import { depositBonusStore } from './deposit-bonus.store';
import { lastDepositSubmitStore } from './last-deposit.store';
import { paymentStore } from './payment.store';
import { valentineStore } from './valentine.store';
import { easterStore } from './easter.store';

export const GlobalStore = {
  PAYMENT_STORE: paymentStore,
  LAST_DEPOSIT_SUBMIT_STORE: lastDepositSubmitStore,
  DEPOSIT_BONUS_STORE: depositBonusStore,
  VALENTINE_STORE: valentineStore,
  EASTER_STORE: easterStore,
};

export enum GlobalStoreName {
  PAYMENT_STORE = 'PAYMENT_STORE',
  LAST_DEPOSIT_SUBMIT_STORE = 'LAST_DEPOSIT_SUBMIT_STORE',
  DEPOSIT_BONUS_STORE = 'DEPOSIT_BONUS_STORE',
  VALENTINE_STORE = 'VALENTINE_STORE',
  EASTER_STORE = 'EASTER_STORE',
}

export type GlobalStoreNameType = keyof typeof GlobalStore;

export type GlobalStoreType = typeof GlobalStore;
