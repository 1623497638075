import { StoreEntityType } from 'ngx-unificator/store';
import { IBasePromo } from '../../page/promo/base-promo';

export interface IEasterState {
  page: IEasterPage;
  currentBonus: ICurrentBonusItem;
  currentPrize: ICurrentPrizeItem;
  isUserCanParticipateInPromo: boolean;
  isUserCanPartiallyParticipateInPromo: boolean;
  isUserAlreadyTakenLootboxes: boolean;
  isLoadedPromo: boolean;
  isRouteFromPromo: boolean;
  isAllWelcomePackClaimed: boolean;
}

export interface IEasterPage extends IBasePromo {
  Title: string;
  Bonuses: IEasterBonuses[];
  Description: string;
  PrizePoolTitle: string;
  Prize: string;
  PrizeRule: string;
  DailyBonusRule: string;
  DailyMissionTitle: string;
  DailyMissionRule: string;
  HowItWorksTitle: string;
  HowItWorksSteps: { desc: string }[];
  GamesTitle: string;
  GamesDescription: string;
  TournamentsTitle: string;
  TryTomorrowTitle: string;
  DailyMissionDoneTitle: string;
  DailyMissionDoneDesc: string;
  HuntTitle: string;
  HuntDesc: string;
  HuntRule: string;
  HowItWorksTerms: string;
  GeneralTerms: string;
  unpublishAt: any;
  publishAt: any;
}

export interface IEasterBonuses {
  title: string,
  id: string,
  rule: string,
  isTaken: boolean,
  type: string
}

export interface ICurrentBonusItem {
  fs?: any;
  cash?: any;
  welcomeLootbox?: any;
  promoLootbox?: any;
}

export interface ICurrentPrizeItem {
  fs_count?: number;
  cash?: any;
}

export const easterStore: StoreEntityType<IEasterState> = {
  page: null,
  currentBonus: null,
  currentPrize: null,
  isUserCanParticipateInPromo: false,
  isUserCanPartiallyParticipateInPromo: false,
  isUserAlreadyTakenLootboxes: false,
  isLoadedPromo: false,
  isRouteFromPromo: false,
  isAllWelcomePackClaimed: false,
};

export type EasterStoreEntity = typeof easterStore;
